.App {
  margin-top: 20px;
}

.App-header {
  margin-bottom: 15px;
  max-width: 800px;
}

.App-header-user {
  float: right;
  text-align: right;
  font-size: 14px;
}

.App-header-user button {
  padding-right: 0;
  font-family: Georgia, 'Bitstream Charter', serif;
}

.App-header img {
  width: 100px;
  height: 100px;
  display: inline-block;
}

.App-header h1 {
  font-size: 28px;
  margin-top: 7px;
  margin-bottom: 0;
  font-family: Cambria, 'Times New Roman', serif;
  font-weight: bold;
  border: none;
  padding-bottom: 1px;
}

.App-header h2 {
  color: #7e7e7e;
  font-size: 20px;
  margin-top: 0;
  font-family: Cambria, 'Times New Roman', serif;
  font-weight: normal;
  margin-bottom: 10px;
}

.App-header-text {
  display: inline-block;
  vertical-align: top;
  margin-left: 20px;
}

.App-link {
  color: #61dafb;
}



a, span.link {
  color: #2a55ab;
  text-decoration: none;
}

a:hover {
  color: #5aa0ff;
}

.gallery h3 {
  margin-bottom: 0;
  font-size: 16px;
}

.gallery p {
  margin-top: 3px;
  padding-left: 5px;
  font-size: 14px;
}

.gallery {
  border-top: 1px dotted #777;
  max-width: 800px;
}

.comment {
  font-size: 13px;
  color: darkred;
  white-space: pre-wrap;
  max-width: 800px;
}

iframe {
  border: solid 1px #aaa;
  width: 100%;
  margin-top: 20px;
  height: 500px;
}

.list span {
  font-size: 13px;
  font-weight: normal;
  font-style: italic;
  margin-left: 5px;
  margin-bottom: 5px;
  display: block;
}

.ant-select-item.separator {
  margin-bottom: 10px;
}

.ant-select-item.separator::after {
  content: "";
  border-bottom: 1px solid #ccc;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -5px;
}

.link-disabled {
  opacity: 0.5;
}

.text-disabled {
  opacity: 0.5;
  font-style: italic;
}

.volume-list {
  margin-top: 5px
}
.volume-list .ant-collapse-expand-icon {
  padding-inline-end: 5px !important;
}
.volume-list-item .ant-collapse-header {
  padding: 1px 0 !important;
}

.article-list {
  margin-top: 20px;
  max-width: 800px;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.article-list .pages {
  float: right;
}
.article-list a {
  display: block;
  font-size: 17px;
  margin-top: 2px;
}

.App.center-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App.center-layout > div.ant-layout {
  width: 100%;
  padding: 0 20px;
}

@media screen and (min-width: 820px) {
  .App.center-layout > header, .App.center-layout > div {
    min-width: 800px;
  }
}

.ant-dropdown ul {
  max-height: 250px;
  overflow-y: auto !important;
}